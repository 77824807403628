import React, { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash'
import { useLocation, useHistory } from 'react-router-dom'
import { Flex, Button, Input, FormLabel, Stack, FormControl, Image, useToast } from '@chakra-ui/react';

import logo from 'assets/pagex_logo.png'
import api from 'services/api';

interface EmailFormDTO {
  email: string
  email_confirmation: string
}

const Email: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const toast = useToast()
  const history = useHistory()
  const location = useLocation()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isSubmitingForm, setIsSubmitingForm] = useState(false);

  const [formData, setFormData] = useState({} as EmailFormDTO);

  const [token,] = useState<string | null>(() => {
    const urlParams = new URLSearchParams(location.search)
    return urlParams.get('token')
  });

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler = useCallback(async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e?.preventDefault()

    setIsSubmitingForm(true)
    api.put('/public/customer/reset_email', { ...formData, token }).then(() => {
      toast({
        title: 'Sucesso',
        description: 'Redefinição de e-mail com sucesso. Você vai receber um e-mail no novo endereço informado com as instruções.',
        status: 'success',
      })
    }).catch((error) => {
      console.log(error)
      const errorMessage = get(error, 'response.data', 'Erro genérico')
      toast({
        title: 'Sucesso',
        description: typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage),
        status: 'success',
      })
    })
    setIsSubmitingForm(false)
  }, [formData, toast, token])

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { name, value } } = e
    setFormData(currentPayload => ({ ...currentPayload, [name]: value }))
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    if (!token) {
      toast({
        title: 'Aviso',
        description: 'Falha ao carregar a página. Por favor acesse novamente o link que você por e-mail.',
        duration: 5000,
        status: 'info',
      })
      history.replace('/')
    }
  }, [history, toast, token])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex textAlign="center" fontSize="xl" minH='100vh' justifyContent='center' alignItems='center'>
      <Flex borderRadius={12} py={8} px={12} boxShadow="lg">
        <form onSubmit={submitHandler}>
          <Stack spacing={4}>
            <Image src={logo} objectFit="scale-down" />

            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                required
                name="email"
                type="email"
                placeholder="Informe o novo e-mail"
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Repita</FormLabel>
              <Input
                required
                name="email_confirmation"
                type="email"
                placeholder="Repita o e-mail"
                onChange={handleInputChange}
              />
            </FormControl>

            <Button
              type="submit"
              colorScheme="twitter"
              disabled={formData.email !== formData.email_confirmation}
              isLoading={isSubmitingForm}
            >
              Definir novo e-mail
            </Button>
          </Stack>
        </form>
      </Flex>
    </Flex>
  );
}

export default Email;