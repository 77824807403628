import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useToast } from "@chakra-ui/react"

import api from 'services/api'

import {
  AuthContextValues,
  User,
  LogInPayload,
  LogInResponse,
} from './interfaces'

const STORAGE_KEYS = {
  USER: '@pagex:user',
  TOKEN: '@pagex:token',
}

const AuthContext = createContext({} as AuthContextValues)

export const AuthContextProvider: React.FC = ({ children }) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()
  const toast = useToast()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState(true)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const saveUserToStorage = useCallback((user: User) => {
    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user))
  }, [])

  const saveTokenToStorage = useCallback((token: string) => {
    localStorage.setItem(STORAGE_KEYS.TOKEN, token)
  }, [])

  const loadUserFromStorage = useCallback(() => {
    const userStr = localStorage.getItem(STORAGE_KEYS.USER)
    if (!userStr) return undefined
    return JSON.parse(userStr)
  }, [])

  const loadTokenFromStorage = useCallback(() => {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN)
    if (!token) return
    return token
  }, [])

  const logIn = useCallback(
    async ({ email, password }: LogInPayload) => {
      setIsLoading(true)
      try {
        const newSessionPayload = { email, password }
        const {
          data: { token, user: userData },
        } = await api.post<LogInResponse>('/public/sessions', newSessionPayload)

        setUser(userData)
        saveUserToStorage(userData)
        api.defaults.headers.Authorization = `Bearer ${token}`
        saveTokenToStorage(token)
        setIsLoading(false)
        history.replace('/dashboard')
      } catch (error) {
        setIsLoading(false)
        let errorMessage =
          'Infelizmente houve uma falha no seu login. Reveja os dados e tente novamente.'

        if (error.response?.data?.errors) {
          errorMessage = error.response.data.errors
            .map((err: { [key: string]: string }) => err.message)
            .join('\n')
        }

        toast({
          title: 'Aviso',
          description: errorMessage,
          status: 'error'
        })
      }
    },
    [history, saveTokenToStorage, saveUserToStorage, toast],
  )

  const logOut = useCallback(() => {
    localStorage.removeItem(STORAGE_KEYS.USER)
    localStorage.removeItem(STORAGE_KEYS.TOKEN)

    setUser(undefined)
    api.defaults.headers.Authorization = ''
  }, [])

  const updateUserData = useCallback(async () => {
    try {
      const { data: userProfile } = await api.get<User>('/app/profiles')

      setUser(userProfile)
      saveUserToStorage(userProfile)
    } catch (error) {
      console.trace('Erro ao atualizar perfil do usuário', error)
    }
  }, [saveUserToStorage])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    const token = loadTokenFromStorage()
    const user = loadUserFromStorage()

    if (token && user) {
      setUser(user)
      api.defaults.headers.Authorization = `Bearer ${token}`
    }

    setIsLoading(false)
  }, [loadTokenFromStorage, loadUserFromStorage])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const authContextValue: AuthContextValues = useMemo(
    () => ({
      user,
      isLoggedIn: !!user,
      logIn,
      logOut,
      isLoading,
      updateUserData,
    }),
    [isLoading, logIn, logOut, updateUserData, user],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  return context
}
