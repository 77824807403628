import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Box,
  Divider,
  SimpleGrid,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import Chart from "react-apexcharts";
import { parseInt } from 'lodash'

import Navbar from 'components/Navbar';
import api from 'services/api';

interface ApiChartResponse {
  dates: string[],
  activationsVector: { date: string; value: number }[]
  inactivationsVector: { date: string; value: number }[]
}

interface ApiDashboardResponse {
  customersCount: string
  customersDisabledCount: string
  customersEnabledCount: string
  customersEnabledCurrentMonthCount: string
  customersEnabledCurrentWeekCount: string
  customersEnabledLastDayCount: string
  customersEnabledLastMonthCount: string
  customersEnabledLastWeekCount: string
  customersEnabledTodayCount: string
  customersToBeDisabledCount: string
  customersToBeDisabledNextMonthCount: string
  customersToBeDisabledNextWeekCount: string
}

export default function Dashboard() {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const toast = useToast()
  const columns = useBreakpointValue({ xl: 4, lg: 3, md: 2, sm: 1 })

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [chartXAxis, setChartXAxis] = useState<ApiChartResponse['dates']>([]);
  const [activations, setActivations] = useState<ApiChartResponse['activationsVector']>([]);
  const [inactivations, setInactivations] = useState<ApiChartResponse['inactivationsVector']>([]);
  const [dashboardData, setDashboardData] = useState<ApiDashboardResponse>();

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const syncCustomer = useCallback(() => {
    api.post('/app/customers/sync').then(() => {
      toast({
        title: 'Sucesso',
        description: 'A sincronização foi iniciada. Você receberá um e-mail ao final.',
        status: 'success',
      })
    }).catch(() => {
      toast({
        title: 'Aviso',
        description: 'Houve um erro ao executar a funcão.',
        status: 'error',
      })
    })
  }, [toast])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    api.get<ApiDashboardResponse>('/app/dashboard').then(({ data }) => {
      setDashboardData(data)
    })
  }, [])

  useEffect(() => {
    api.get<ApiChartResponse>('/app/chart').then(({ data: { dates, activationsVector, inactivationsVector } }) => {
      setChartXAxis(dates)
      setActivations(activationsVector)
      setInactivations(inactivationsVector)
    })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  if (!dashboardData) return <p>Carregando ...</p>

  return (
    <>
      <Navbar />

      <SimpleGrid columns={columns} spacing={4} p="1rem">
        <Button
          onClick={syncCustomer}
          bgColor="blue.400"
          textColor="white"
          variant="solid"
          _hover={{
            bg: 'blue.300',
          }}
        >
          Sincronizar status com Landingi
        </Button>
      </SimpleGrid>

      <Divider />

      <SimpleGrid columns={columns} spacing={4} p="1rem">
        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Projeção de Clientes Ativos</StatLabel>
          <StatNumber>{parseInt(dashboardData?.customersEnabledCount) - parseInt(dashboardData?.customersToBeDisabledCount)}</StatNumber>
        </Stat>
        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Clientes totais</StatLabel>
          <StatNumber>{dashboardData?.customersCount}</StatNumber>
        </Stat>
        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Clientes desabilitados</StatLabel>
          <StatNumber>{dashboardData?.customersDisabledCount}</StatNumber>
        </Stat>
        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Clientes ativos</StatLabel>
          <StatNumber>{dashboardData?.customersEnabledCount}</StatNumber>
        </Stat>
        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Clientes ativados hoje</StatLabel>
          <StatNumber>{dashboardData?.customersEnabledTodayCount}</StatNumber>
        </Stat>
        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Clientes ativados na semana</StatLabel>
          <StatNumber>{dashboardData?.customersEnabledCurrentWeekCount}</StatNumber>
        </Stat>
        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Clientes ativados no mês</StatLabel>
          <StatNumber>{dashboardData?.customersEnabledCurrentMonthCount}</StatNumber>
        </Stat>
        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Clientes com desativação agendada</StatLabel>
          <StatNumber>{dashboardData?.customersToBeDisabledCount}</StatNumber>
        </Stat>
        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Clientes com desativação agendada na semana</StatLabel>
          <StatNumber>{dashboardData?.customersToBeDisabledNextWeekCount}</StatNumber>
        </Stat>
        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Clientes com desativação agendada no mês</StatLabel>
          <StatNumber>{dashboardData?.customersToBeDisabledNextMonthCount}</StatNumber>
        </Stat>

      </SimpleGrid>

      <Divider />

      <SimpleGrid columns={columns} spacing={4} p="1rem">

        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Evolução MoM</StatLabel>
          <StatNumber>{Number(dashboardData.customersEnabledCurrentMonthCount) - Number(dashboardData.customersEnabledLastMonthCount)}</StatNumber>
          <StatHelpText>
            <StatArrow type={Number(dashboardData.customersEnabledCurrentMonthCount) > Number(dashboardData.customersEnabledLastMonthCount) ? "increase" : "decrease"} />
            {(Number(dashboardData.customersEnabledCurrentMonthCount) / Number(dashboardData.customersEnabledLastMonthCount)).toFixed(2) || 0} %
          </StatHelpText>
        </Stat>

        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Evolução WoW</StatLabel>
          <StatNumber>{Number(dashboardData.customersEnabledCurrentWeekCount) - Number(dashboardData.customersEnabledLastWeekCount)}</StatNumber>
          <StatHelpText>
            <StatArrow type={Number(dashboardData.customersEnabledCurrentWeekCount) > Number(dashboardData.customersEnabledLastWeekCount) ? "increase" : "decrease"} />
            {(Number(dashboardData.customersEnabledCurrentWeekCount) / Number(dashboardData.customersEnabledLastWeekCount)).toFixed(2) || 0} %
          </StatHelpText>
        </Stat>

        <Stat borderColor="gray.200" borderWidth={1} borderRadius="8px" p="0.5rem">
          <StatLabel>Evolução DoD</StatLabel>
          <StatNumber>{Number(dashboardData.customersEnabledTodayCount) - Number(dashboardData.customersEnabledLastDayCount)}</StatNumber>
          <StatHelpText>
            <StatArrow type={Number(dashboardData.customersEnabledTodayCount) > Number(dashboardData.customersEnabledLastDayCount) ? "increase" : "decrease"} />
            {(Number(dashboardData.customersEnabledTodayCount) / Number(dashboardData.customersEnabledLastDayCount)).toFixed(2) || 0} %
          </StatHelpText>
        </Stat>


      </SimpleGrid>

      <Divider />

      <Box spacing={4} p="1rem">
      <Chart
        options={{
          chart: {
            id: "evolution"
          },
          xaxis: {
            categories: chartXAxis
          }
        }}
        series={[
          {
            name: "Ativações",
            data: activations.map(({ value }) => value)
          },
          {
            name: "Inativações",
            data: inactivations.map(({ value }) => value)
          }
        ]}
        type="line"
        height="500"
      />
      </Box>
    </>
  );
}
