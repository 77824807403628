import * as React from "react"
import { ChakraProvider, theme, CSSReset } from "@chakra-ui/react"

import Routes from 'routes'

import { AuthContextProvider } from 'contexts/auth'

export const App = () => (
  <>
    <CSSReset />
    <ChakraProvider theme={theme}>
      <AuthContextProvider>
        <Routes />
      </AuthContextProvider>
    </ChakraProvider>
  </>
)
