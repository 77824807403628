import React, { useEffect, useState } from "react";
import { useToast } from '@chakra-ui/react'
import { get } from 'lodash'

// Components
import Navbar from "components/Navbar";
import Table from "components/Table";

// Services
import api from "services/api";

// Interfaces
import { UserDTO } from './interface'

const UserIndex: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const toast = useToast()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [users, setUsers] = useState<UserDTO[]>([]);

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const columns = React.useMemo(
    () => [
      {
        Header: 'Usuários',
        columns: [
          {
            Header: 'Id',
            accessor: 'id',
          },
          {
            Header: 'Nome',
            accessor: 'name',
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Criado em',
            id: 'created_at',
            accessor: (v: string) => new Date(v).toJSON(),
          },
        ],
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    api
      .get<UserDTO[]>('/app/users')
      .then(({ data: logs }) => setUsers(logs))
      .catch((error) => {
        console.trace(error)
        toast({
          title: 'Erro',
          description: get(error, 'response.data.error', 'Não foi possível carregar os dados.')
        })
      })
  }, [toast])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <>
      <Navbar />
      <Table columns={columns} data={users} />
    </>
  );
}

export default UserIndex
