import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

// Pages
import Auth from 'pages/Auth'
import Logs from 'pages/Logs'
import Users from 'pages/Users'
import Email from 'pages/Email'
import Customers from 'pages/Customers'
import Dashboard from 'pages/Dashboard'

const Routes = () => (
  <Switch>
    {/* Open Pages */}
    <Route path="/" exact component={Auth} />
    <Route path="/email" exact component={Email} />

    {/* Private Pages */}
    <Route path="/logs" component={Logs} isPrivate />
    <Route path="/users" component={Users} isPrivate />
    <Route path="/customers" component={Customers} isPrivate />
    <Route path="/dashboard" component={Dashboard} isPrivate />
  </Switch>
)

export default Routes
