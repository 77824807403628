import React, { useEffect, useState } from "react";
import { useToast } from '@chakra-ui/react'
import { get } from 'lodash'

// Components
import Navbar from "components/Navbar";
import Table from "components/Table";

// Interfaces
import { LogDTO } from './interface'
import api from "services/api";

const LogIndex: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const toast = useToast()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [logs, setLogs] = useState<LogDTO[]>([]);

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const columns = React.useMemo(
    () => [
      {
        Header: 'Logs de Webhook',
        columns: [
          {
            Header: 'Id',
            accessor: 'id',
          },
          {
            Header: 'Provedor',
            accessor: 'payment_provider',
          },
          {
            Header: 'Log',
            id: 'payload',
            accessor: (v: any) => JSON.stringify(v),
          },
          {
            Header: 'Criado em',
            id: 'created_at',
            accessor: (v: string) => new Date(v).toJSON(),
          },
        ],
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    api
      .get<LogDTO[]>('/app/logs')
      .then(({ data: logs }) => setLogs(logs))
      .catch((error) => {
        console.trace(error)
        toast({
          title: 'Erro',
          description: get(error, 'response.data.error', 'Não foi possível carregar os dados.')
        })
      })
  }, [toast])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <>
      <Navbar />
      <Table columns={columns} data={logs} />
    </>
  );
}

export default LogIndex
