import React, { useCallback, useState } from 'react';
import { Flex, Button, Input, FormLabel, Stack, FormControl, InputRightElement, InputGroup, Image } from '@chakra-ui/react';

import { useAuth } from 'contexts/auth'

import logo from 'assets/pagex_logo.png'

interface LoginFormDTO {
  email: string
  password: string
}

const Auth: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { logIn } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isSubmitingForm, setIsSubmitingForm] = useState(false);

  const [formData, setFormData] = useState({} as LoginFormDTO);

  const [showPassword, setShowPassword] = useState(false);

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler = useCallback(async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e?.preventDefault()

    setIsSubmitingForm(true)
    logIn(formData)
    setIsSubmitingForm(false)
  }, [formData, logIn])

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { name, value } } = e
    setFormData(currentPayload => ({ ...currentPayload, [name]: value }))
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex textAlign="center" fontSize="xl" minH='100vh' justifyContent='center' alignItems='center'>
      <Flex borderRadius={12} py={8} px={12} boxShadow="lg">
        <form onSubmit={submitHandler}>
          <Stack spacing={4}>
            <Image src={logo} objectFit="scale-down" />

            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                required
                name="email"
                type="email"
                placeholder="Seu e-mail"
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Senha</FormLabel>
              <InputGroup size="md">
                <Input
                  required
                  pr="4.5rem"
                  name="password"
                  placeholder="Sua senha"
                  type={showPassword ? "text" : "password"}
                  onChange={handleInputChange}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={() => setShowPassword(show => !show)}>
                    {showPassword ? "Ocultar" : "Exibir"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Button
              type="submit"
              colorScheme="twitter"
              isLoading={isSubmitingForm}
            >
              Login
            </Button>
          </Stack>
        </form>
      </Flex>
    </Flex>
  );
}

export default Auth;