import React, { useCallback, useEffect, useState } from "react";
import {
  Input,
  Flex,
  Spinner,
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text
} from '@chakra-ui/react'
import { AxiosRequestConfig } from 'axios'
import { get, sortBy } from 'lodash'

// Components
import Navbar from "components/Navbar";
import Table from "components/Table";

// Services
import api from "services/api";

// Interfaces
import { CustomerDTO } from './interface'

const CustomerIndex: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const toast = useToast()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [query, setQuery] = useState<string>();
  const [inputQuery, setInputQuery] = useState<string>();
  const [customers, setCustomers] = useState<CustomerDTO[]>([]);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
  const [isBulkUpdatingCustomers, setIsBulkUpdatingCustomers] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState<CustomerDTO[]>([]);
  const [actionType, setActionType] = useState<'enable' | 'disable'>();

  const { isOpen, onOpen, onClose } = useDisclosure()

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const columns = React.useMemo(
    () => [
      {
        Header: 'Clientes',
        columns: [
          {
            Header: 'Id',
            accessor: 'id',
          },
          {
            Header: 'Id Landingi',
            accessor: 'landingi_id',
          },
          {
            Header: 'Nome Landingi',
            accessor: 'landingi_name',
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Nome',
            accessor: 'name',
          },
          {
            Header: 'Ativo',
            id: 'landingi_is_active',
            accessor: (v: CustomerDTO) => v.landingi_is_active ? '✔' : '⤬',
          },
          {
            Header: 'Editor',
            id: 'landingi_is_editor',
            accessor: (v: CustomerDTO) => v.landingi_is_editor ? '✔' : '⤬',
          },
          {
            Header: 'Data de Inativação',
            id: 'disable_landingi_account_at',
            accessor: (v: CustomerDTO) => v.disable_landingi_account_at ? v.disable_landingi_account_at : 'n/a',
          },
        ],
      },
    ],
    []
  )


  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const fetchCustomer = useCallback(
    (query?: string, isErase?: boolean) => {
      setIsFetchingCustomers(true);

      if (isErase) {
        setCustomers([])
      }

      api
        .get<CustomerDTO[]>('/app/customers', { params: { q: query } })
        .then(({ data: customers }) => {
          if (!customers.length) {
            toast({
              title: 'Aviso',
              description: 'Nenhum cliente encontrado, tente novamente com outros termos.',
              status: 'info'
            })
            return
          }
          setCustomers(customers)
        })
        .catch((error) => {
          console.trace(error)
          toast({
            title: 'Erro',
            description: get(error, 'response.data.error', 'Não foi possível carregar os dados.'),
            status: 'error'
          })
        })
        .finally(() => setIsFetchingCustomers(false))
    },
    [toast],
  );

  const handleBulkEnableAction = useCallback(() => {
    setActionType('enable')
    onOpen()
  }, [onOpen])

  const handleBulkDisableAction = useCallback(() => {
    setActionType('disable')
    onOpen()
  }, [onOpen])

  const handleCancelBulkAction = useCallback(() => {
    onClose()
  }, [onClose])

  const handleConfirmBulkAction = useCallback(async () => {
    onClose()

    const requestConfig: AxiosRequestConfig = {
      method: 'PUT',
      url: '/app/customers/bulk_' + actionType,
      data: {
        customersIds: selectedCustomers.map(({ id }) => id)
      },
    }

    setIsBulkUpdatingCustomers(true)
    try {
      const response = await api(requestConfig)
      fetchCustomer(undefined, true)
      toast({
        title: 'Sucesso',
        description: response.data,
        status: 'success',
        duration: 4000,
      })
    } catch (error) {
      console.trace(error)
      toast({
        title: 'Aviso',
        description: 'Falha ao fazer processamento em massa. Veja o console do navegador para mais detalhes.',
        status: 'error'
      })
    }

    setIsBulkUpdatingCustomers(false)
  }, [actionType, fetchCustomer, onClose, selectedCustomers, toast])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    fetchCustomer(query, false)
  }, [fetchCustomer, query])

  useEffect(() => {
    /**
     * Search input debounced effect.
     */
    const updateQueryTimeout = setTimeout(() => {
      setQuery(inputQuery)
    }, 800)

    return () => {
      clearTimeout(updateQueryTimeout)
    }
  }, [inputQuery])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <>
      <Navbar />
      <Flex alignItems="center" pt="2rem" pl="2rem" pb="0.5rem">
        <Input
          onChange={(e) => setInputQuery(e.target.value)}
          defaultValue={inputQuery}
          placeholder="Busque por nome, id ou email (vazio para todos)"
          size="md"
          marginRight="0.5rem"
          maxW="400px"
        />
        {(isFetchingCustomers || isBulkUpdatingCustomers) ? <Spinner /> : (
          <>
            <Button
              bgColor="green.500"
              color="white"
              disabled={!selectedCustomers.length}
              _hover={{ bg: '#22543D' }}
              onClick={handleBulkEnableAction}
            >
              Ativar
            </Button>
            <Button
              bgColor="red.500"
              color="white"
              ml="0.5rem"
              disabled={!selectedCustomers.length}
              _hover={{ bg: '#9B2C2C' }}
              onClick={handleBulkDisableAction}
            >
              Inativar
            </Button>
          </>
        )}
      </Flex>
      <Table columns={columns} data={customers} selectedRowsObjectSetter={setSelectedCustomers} />
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmação de {actionType === 'disable' ? 'Inativação' : 'Ativação'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text as="h3" fontWeight="bold">Clientes selecionados ({selectedCustomers.length})</Text>
            <br />
            {sortBy(selectedCustomers, 'name').map(({ name, email }) => (
              <p key={email}>{name} - {email}</p>
              ))}
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCancelBulkAction}>Cancelar</Button>
            <Button ml="0.5rem" colorScheme="twitter" onClick={handleConfirmBulkAction}>Confirmar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CustomerIndex
